import _ from 'lodash'
import {
  updateDesktopLayout,
  updateAlignment,
  updateAgreeTermsLayoutProps,
  updateMobileLayout,
} from './get-subscribers-layout'
import { GetSubscribersFieldEnhancer, GSExtraData } from './get-subscribers-types'
import { isSkinWithFieldTitlesGS } from './get-subscribers-utils'
import { inputStyle, agreeStyle } from './get-subscribers-style'

export const textInputFieldLabelPlaceholderMapping = (extraData: GSExtraData, field) => {
  switch (field.name) {
    case 'email':
      return {
        label: extraData.desktopLayouts.email.label?.textData.label,
        placeholder: extraData.desktopLayouts.email.input.textData.placeholder,
      }
    case 'name':
      return {
        label: extraData.desktopLayouts.name.label?.textData.label,
        placeholder: extraData.desktopLayouts.name.input.textData.placeholder,
      }
    case 'phone':
      return {
        label: extraData.desktopLayouts.phoneNumber.label?.textData.label,
        placeholder: extraData.desktopLayouts.phoneNumber.input.textData.placeholder,
      }
    case 'agree':
      return {
        label: extraData.desktopLayouts.consent.text.textData.label,
      }
  }
}

export const updateConfig: GetSubscribersFieldEnhancer = ({ convertedField }) => {
  convertedField.config = { ...(convertedField as any).connectionConfig }
  delete (convertedField as any).connectionConfig
  return convertedField
}

export const updateCrmLabel: GetSubscribersFieldEnhancer = ({
  fieldData,
  convertedField,
  extraData,
}) => {
  const { label } = textInputFieldLabelPlaceholderMapping(extraData, fieldData)
  convertedField.config.crmLabel = label.substring(0, 25).trim() || fieldData.name
  return convertedField
}

const updateLabel: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
  extraData,
}) => {
  if (isSkinWithFieldTitlesGS(settings)) {
    convertedField.data.placeholder = ''
    convertedField.props.placeholder = ''
    convertedField.data.label = textInputFieldLabelPlaceholderMapping(extraData, fieldData).label
  }
  return convertedField
}

export const updatePlaceholder: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
  extraData,
}) => {
  if (!isSkinWithFieldTitlesGS(settings)) {
    const placeholder = textInputFieldLabelPlaceholderMapping(extraData, fieldData).placeholder
    convertedField.data.label = ''
    convertedField.data.placeholder = placeholder
    convertedField.props.placeholder = placeholder
    convertedField.props.textPadding = 8
  }
  return convertedField
}

const updateAgreeTerms: GetSubscribersFieldEnhancer = ({
  settings,
  fieldData,
  convertedField,
  extraData,
}) => {
  if (fieldData.name !== 'agree') {
    return convertedField
  }

  convertedField.data.checked = false

  const label = extraData.desktopLayouts.consent.text.textData.label
  const indexOfLink = label.lastIndexOf(settings.gdpr.policyLinkText)
  convertedField.data.label = indexOfLink >= 0 ? label.substring(0, indexOfLink) : label

  if (settings.gdpr.isPrivacyPolicyLinkEnabled) {
    convertedField.data.link = {
      type: 'ExternalLink',
      target: '_blank',
      url:
        settings.gdpr.policyLinkUrl || `http://www.${settings.gdpr.policyLinkText || 'google'}.com`,
    }
    settings.gdpr.policyLinkUrl || `www.${settings.gdpr.policyLinkText || 'google'}.com`
    convertedField.data.linkLabel = settings.gdpr.policyLinkText
  }

  return convertedField
}

const updateRequired: GetSubscribersFieldEnhancer = ({ fieldData, convertedField }) => {
  convertedField.props.required = fieldData.required
  return convertedField
}

const paddingEnhancer: GetSubscribersFieldEnhancer = ({ convertedField }) => {
  return _.merge({}, convertedField, { props: { textPadding: 5 } })
}

const fieldStyle: GetSubscribersFieldEnhancer = ({
  convertedField,
  settings,
  extraData,
  fieldData,
}) => {
  const getStyle = fieldData.name === 'agree' ? agreeStyle : inputStyle
  return _.merge({}, convertedField, {
    style: { style: getStyle(settings.style.appStyle, extraData) },
  })
}

const commonEnhancers: GetSubscribersFieldEnhancer[] = [
  paddingEnhancer,
  updateConfig,
  updateCrmLabel,
  updateDesktopLayout,
  updateMobileLayout,
  updateAlignment,
  fieldStyle,
]

export const textFieldEnhancers: GetSubscribersFieldEnhancer[] = [
  ...commonEnhancers,
  updatePlaceholder,
  updateRequired,
  updateLabel,
]

export const agreeFieldEnhancers: GetSubscribersFieldEnhancer[] = [
  ...commonEnhancers,
  updateAgreeTerms,
  updateAgreeTermsLayoutProps,
]
