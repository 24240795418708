import { FIELD_COMPONENT_TYPES } from '@wix/forms-common'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'
import _ from 'lodash'
import { FIELDS } from '../../../constants/roles'
import { SPACE_BETWEEN_FIELDS } from '../fields/api'
import { getDefaultLabel } from '../fields/utils'
import { getComponentLayout, getItemLayout } from '../layout/responsive-utils'
import { FieldLayoutProp } from '../layout/utils'
import { fieldsStore } from '../preset/fields/fields-store'
import { calcDominatedValueInArray, isInputField } from '../utils'

// common layout group is not always from the same type, so we enforce it to be
// each group has one dominated type, that some properties will use this callbacks to align to it
const propsTransformationToCommonLayout: {
  [key: string]: {
    fromCommonLayoutToValue?: (propValue: PROPERTIES) => PROPERTIES
    fromValueToCommonLayout?: (propValue: PROPERTIES) => PROPERTIES
  }
} = {
  alignment: {
    fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_ALIGNMENT =>
      value === 'center' || value === 'left' ? 'left' : 'right',
    fromValueToCommonLayout: (value: PROP_ALIGNMENT): PROP_TEXT_ALIGNMENT =>
      value === 'left' ? 'left' : 'right',
  },
  direction: {
    fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_DIRECTION =>
      value === 'center' || value === 'left' ? 'ltr' : 'rtl',
    fromValueToCommonLayout: (value: PROP_DIRECTION): PROP_TEXT_ALIGNMENT =>
      value === 'ltr' ? 'left' : 'right',
  },
  layout: {
    fromValueToCommonLayout: (value: PROP_LAYOUT): PROP_LABEL_POSITION =>
      value === 'vertical' ? 'top' : 'bottom',
    fromCommonLayoutToValue: (value: PROP_LABEL_POSITION): PROP_LAYOUT =>
      value === 'top' || value === 'side' ? 'vertical' : 'horizontal',
  },
  buttonAlignment: {
    fromValueToCommonLayout: (value: PROP_DIRECTION): PROP_LABEL_POSITION =>
      value === 'ltr' ? 'top' : 'bottom',
    fromCommonLayoutToValue: (value: PROP_LABEL_POSITION): PROP_DIRECTION =>
      value === 'top' || value === 'side' ? 'ltr' : 'rtl',
  },
  shapeSpacing: {
    fromCommonLayoutToValue: (spacing: number): number => Math.max(1, Math.min(spacing, 50)),
  },
  buttonSize: {
    fromCommonLayoutToValue: (size: number): number => Math.max(6, Math.min(size / 1.5, 100)),
    fromValueToCommonLayout: (size: number) => size * 1.5,
  },
  shapeSize: {
    fromCommonLayoutToValue: (size: number) => Math.max(15, Math.min(size, 100)),
  },
  margin: {
    fromCommonLayoutToValue: (size: number) => Math.max(1, Math.min(size, 30)),
  },
}

const similarProps: string[][] = [
  ['textAlignment', 'labelAlignment', 'filesAlignment', 'alignment', 'direction'], // dominated type: textAlignment
  ['layout', 'labelPosition', 'buttonAlignment'], // dominated type: labelPosition
  ['textPadding', 'spacing', 'margin'], // dominated type: number (limits: 0 - 100 , 0 - 100, 1 - 30)
  ['labelPadding', 'titlePaddingStart'], // dominated type: number (limits: 0 - 100, 0 - 100)
  ['labelMargin', 'shapeSpacing', 'buttonsMargin', 'titleMarginBottom'], // dominated type: number (limits: 0 - 100, 1 - 50 , 0 - 100, 0 - 100)
  ['buttonSize', 'shapeSize'], // dominated type: number (limits: 6 - 100, 15 - 100),
]

const similarComponentTypes: FIELD_COMPONENT_TYPES[][] = [
  [FIELD_COMPONENT_TYPES.RADIO_GROUP, FIELD_COMPONENT_TYPES.CHECKBOX_GROUP],
  [
    FIELD_COMPONENT_TYPES.ADDRESS_INPUT,
    FIELD_COMPONENT_TYPES.TEXT_INPUT,
    FIELD_COMPONENT_TYPES.DATE_PICKER,
    FIELD_COMPONENT_TYPES.COMBOBOX,
    FIELD_COMPONENT_TYPES.ADDRESS_INPUT,
  ],
]

const getFieldTransformer = (fieldType) =>
  _.get(fieldsStore.allFieldsData[fieldType], 'overrideCommonLayoutTransformation')

const getFromValueToCommonLayout = (fieldType, prop) => {
  const fieldTransformer = getFieldTransformer(fieldType)
  const _fromValueToCommonLayout = (transformer) =>
    _.get(transformer, [prop, 'fromValueToCommonLayout'])
  return (
    _fromValueToCommonLayout(fieldTransformer) ||
    _fromValueToCommonLayout(propsTransformationToCommonLayout) ||
    _.identity
  )
}

const getFromCommonLayoutToValue = (fieldType, prop) => {
  const fieldTransformer = getFieldTransformer(fieldType)
  const _fromCommonLayoutToValue = (transformer) =>
    _.get(transformer, [prop, 'fromCommonLayoutToValue'])
  return (
    _fromCommonLayoutToValue(fieldTransformer) ||
    _fromCommonLayoutToValue(propsTransformationToCommonLayout) ||
    _.identity
  )
}

const getSimilarPropsValues = (fields: FormField[], similarPropsKeys: string[]): PROPERTIES[] => {
  const similarPropsValues = fields.map(({ fieldType, ...fieldProperties }) => {
    const fieldPropsInSimilarPropsKeys: string[] = getFieldPropsInSimilarPropsKeys(
      fieldType,
      similarPropsKeys,
    )
    return fieldPropsInSimilarPropsKeys
      .map((prop) => {
        const fromValueToCommonLayout = getFromValueToCommonLayout(fieldType, prop)
        return fromValueToCommonLayout(fieldProperties[prop])
      })
      .filter((value) => !(value === undefined))
  })
  return _.flatMap(similarPropsValues)
}

const getSimilarPropsValue = (fields: FormField[]) =>
  similarProps.map((props) => calcDominatedValueInArray(getSimilarPropsValues(fields, props)))

const getPropIndex = (propKey: string): number =>
  _.findIndex(similarProps, (props) => _.includes(props, propKey))

const getFieldPropsInSimilarPropsKeys = (fieldType: FieldPreset, similarPropsKeys: string[]) =>
  _.intersection(
    _.get(fieldsStore.allFieldsData[fieldType], 'layoutComponentProps', []),
    similarPropsKeys,
  )

const getCommonFieldProps = (
  fieldType: FieldPreset,
  fields: FormField[],
  propsToCheck: string[],
  fieldProps: any,
) => {
  const similarPropsValue = getSimilarPropsValue(fields)

  const propsFromFields = propsToCheck.reduce((acc, propKey) => {
    const propIndex = getPropIndex(propKey)

    const transformer = getTransformer(fieldType, propKey)

    acc[propKey] =
      similarPropsValue[propIndex] !== undefined
        ? transformer(similarPropsValue[propIndex])
        : _.get(fieldProps, propKey)
    return acc
  }, {})
  return { ...fieldProps, ...propsFromFields }
}

const getFieldPropsFromSimilarField = (
  fieldType: FieldPreset,
  similarField: FormField,
  propsToCheck: string[],
  fieldProps: any,
) => {
  const propsFromSimilarFields = propsToCheck.reduce((acc, propKey) => {
    const fieldTransformer = getFieldTransformer(fieldType)
    const transformer = _.get(fieldTransformer, [propKey, 'fromCommonLayoutToValue'], _.identity)
    acc[propKey] = transformer(similarField[propKey])
    return acc
  }, {})
  if (similarField.inputHeight) {
    propsFromSimilarFields['inputHeight'] = similarField.inputHeight
  }
  if (similarField.inputHeightMobile) {
    propsFromSimilarFields['inputHeightMobile'] = similarField.inputHeightMobile
  }
  return { ...fieldProps, ...propsFromSimilarFields }
}

const getFieldProps = (
  fieldType: FieldPreset,
  propsToCheck,
  fields: FormField[],
  similarField: FormField | undefined,
  fieldProps: any,
) => {
  if (_.isEmpty(propsToCheck)) {
    return fieldProps
  }

  return similarField
    ? getFieldPropsFromSimilarField(fieldType, similarField, propsToCheck, fieldProps)
    : getCommonFieldProps(fieldType, fields, propsToCheck, fieldProps)
}

const getFieldLabel = (
  similarField: FormField | undefined,
  fieldType: FieldPreset,
  fieldData,
): string | undefined => {
  const showLabel = _.get(similarField, 'showLabel') || (!similarField && fieldData.label)
  return showLabel ? getDefaultLabel({ fieldType, ...fieldData }) : undefined
}

const getFieldLayout = (similarField: FormField | undefined, fieldComponent) => {
  const width = _.get(similarField, 'width')
  const height = _.get(similarField, 'height')
  return width !== undefined && height !== undefined
    ? _.assign({}, fieldComponent.layout, {
        width,
        height,
      })
    : fieldComponent.layout
}

const getFieldLayoutResponsive = (
  similarField: FormField | undefined,
  anyField: FormField | undefined,
  fieldComponent: FormField,
): ResponsiveLayout => {
  const createItemsLayout = (layout: AnyItemLayout) => {
    let newLayout: AnyItemLayout = { ...layout }
    const layoutResponsive: ResponsiveLayout = fieldComponent.layoutResponsive
    const itemLayout = layoutResponsive.itemLayouts[0]
    const layoutItemType = layout.type as ResponsiveItemLayoutType

    switch (layoutItemType) {
      case 'GridItemLayout':
        newLayout = {
          ...newLayout,
          gridArea: (itemLayout as GridItemLayout).gridArea,
        } as GridItemLayout
      default:
        newLayout = {
          ...newLayout,
          order: (itemLayout as StackItemLayout).order,
        } as StackItemLayout
    }

    return [{ ...newLayout, id: '' }]
  }

  if (similarField) {
    const similarComponentLayout = getComponentLayout(similarField)
    return similarComponentLayout
      ? {
          ...fieldComponent.layoutResponsive,
          componentLayouts: [similarComponentLayout],
          itemLayouts: createItemsLayout(getItemLayout(similarField)),
        }
      : fieldComponent.layoutResponsive
  } else if (anyField) {
    const anyItemLayouts = getItemLayout(anyField)
    if (fieldComponent.role === FIELDS.ROLE_FIELD_RECAPTCHA) {
      return anyItemLayouts
        ? {
            ...fieldComponent.layoutResponsive,
            itemLayouts: createItemsLayout(anyItemLayouts),
          }
        : fieldComponent.layoutResponsive
    } else {
      const anyComponentLayout = getComponentLayout(anyField)
      const fieldComponentLayout = getComponentLayout(fieldComponent)
      return anyComponentLayout
        ? {
            ...fieldComponent.layoutResponsive,
            componentLayouts: [
              fieldComponentLayout
                ? {
                    ...fieldComponentLayout,
                    ..._.pick(anyComponentLayout, ['width', 'minWidth', 'maxWidth']),
                  }
                : anyComponentLayout,
            ],
            itemLayouts: createItemsLayout(anyItemLayouts),
          }
        : fieldComponent.layoutResponsive
    }
  }

  return fieldComponent.layoutResponsive
}

const getSimilarComponentsByType = (
  componentType: FIELD_COMPONENT_TYPES,
  fields: FormField[],
  globalSimilarity: boolean = false,
): FormField | undefined => {
  const exactFields = fields.filter((field) => field.componentType === componentType)
  if (!globalSimilarity && exactFields.length) {
    return _.last(exactFields)
  }

  const componentTypeGroup = _.find(similarComponentTypes, (group) =>
    _.includes(group, componentType),
  )
  const similarFields = fields.filter((field) =>
    _.includes(componentTypeGroup, field.componentType),
  )
  return _.last(similarFields)
}

export const createFieldWithMostCommonLayout = (
  fieldType: FieldPreset,
  fields: FormField[],
  fieldComponent,
  globalSimilarity: boolean,
  isResponsive: boolean = false,
) => {
  const {
    properties: {
      componentType,
      extraData: {
        connectionConfig: { label: labelFromConnection },
      },
    },
    layoutComponentProps: propsToCheck,
  } = fieldsStore.allFieldsData[fieldType]
  const similarField = getSimilarComponentsByType(componentType, fields, globalSimilarity)

  const newField = _.cloneDeep(fieldComponent)
  if (isResponsive) {
    newField.layoutResponsive = getFieldLayoutResponsive(
      similarField,
      _.find(
        fields,
        (field) => isInputField(field.role) && field.role !== FIELDS.ROLE_FIELD_RECAPTCHA,
      ),
      fieldComponent,
    )
  } else {
    newField.layout = getFieldLayout(similarField, fieldComponent)
  }

  newField.data.label = getFieldLabel(similarField, fieldType, {
    ...fieldComponent.data,
    labelFromConnection,
  })

  newField.props = getFieldProps(
    fieldType,
    propsToCheck,
    fields,
    similarField,
    fieldComponent.props,
  )

  return newField
}

//support only classic
export const relayoutInnerComponentsComplexAddress = ({
  fieldComponents,
  isResponsive,
  formConfig,
  formWidth,
}: {
  fieldComponents: RawComponentStructure[]
  isResponsive: boolean
  formConfig: Partial<ComponentConfig>
  formWidth: number
}) => {
  if (isResponsive) {
    throw new Error('complex field not supported in responsive')
  }

  const { columns, spaceBetweenCols: colSpaceConfig, spaceBetweenRows: rowSpaceConfig } = formConfig
  const spaceBetweenCols = colSpaceConfig || 0
  const spaceBetweenRows = rowSpaceConfig || SPACE_BETWEEN_FIELDS

  const { width, height } = _.first(fieldComponents).layout
  const isOneColumn = !columns || columns === 1 || width * 2 + spaceBetweenCols > formWidth
  const yOffset = height + spaceBetweenRows
  const xOffset = width + spaceBetweenCols
  const addTwoInRow = (
    components: RawComponentStructure[],
    aggregateData: { fields: RawComponentStructure[]; y: number },
  ) => {
    const newAggregateData = _.reduce(
      components,
      ({ fields, x, y }, field) => {
        const updatedField = {
          ...field,
          layout: { ...field.layout, y, x },
        }
        return {
          fields: [...fields, updatedField],
          x: xOffset,
          y,
        }
      },
      { ...aggregateData, x: 0 },
    )
    return {
      fields: newAggregateData.fields,
      y: newAggregateData.y + yOffset,
    }
  }

  const addFieldsEachInRow = (
    components: RawComponentStructure[],
    width: number,
    aggregateData: { fields: RawComponentStructure[]; y: number },
  ) => {
    return _.reduce(
      components,
      ({ fields, y }, field) => {
        const updatedField = {
          ...field,
          layout: { ...field.layout, y, x: 0, width },
        }
        return {
          fields: [...fields, updatedField],
          y: y + yOffset,
        }
      },
      aggregateData,
    )
  }

  const initData = { fields: [], y: 0 }

  if (isOneColumn) {
    return addFieldsEachInRow(fieldComponents, width, initData).fields
  } else {
    const groups = _.chunk(fieldComponents, 2)

    const twoFullRowsData = addFieldsEachInRow(groups[0], width * 2 + spaceBetweenCols, initData)
    const threeRowsData = addTwoInRow(groups[1], twoFullRowsData)
    return addTwoInRow(groups[2], threeRowsData).fields
  }
}

export const getComponentTypeLayoutProp = (
  fieldType: FieldPreset,
  propKey: string,
  propValue: PROPERTIES,
): FieldLayoutProp => {
  const propIndex = getPropIndex(propKey)
  const fieldPropsInSimilarPropsKeys = getFieldPropsInSimilarPropsKeys(
    fieldType,
    similarProps[propIndex],
  )

  return fieldPropsInSimilarPropsKeys.length
    ? fieldPropsInSimilarPropsKeys.reduce((acc, propKey) => {
        const transformer = getTransformer(fieldType, propKey)
        acc[propKey] = transformer(propValue)
        return acc
      }, {})
    : null
}

const getTransformer = (fieldType: FieldPreset, propKey): Function => {
  const { revokedCommonLayoutTransformationProps } = fieldsStore.allFieldsData[fieldType]
  const useCommonLayoutTransformer = !_.includes(revokedCommonLayoutTransformationProps, propKey)
  const transformerToCommonLayout =
    useCommonLayoutTransformer && getFromCommonLayoutToValue(fieldType, propKey)
  return transformerToCommonLayout || _.identity
}
