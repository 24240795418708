import { GetSubscribersEnhancer, GSExtraData } from './get-subscribers-types'
import { isSideLabelSkinGS } from './get-subscribers-utils'
import { FIELDS_ROLES } from '../../../../constants/roles'
import _ from 'lodash'
import greyBox from '../../../../assets/presets/get-subscribe-form-grey-box.json'
import triangle from '../../../../assets/presets/get-subscribe-form-grey-triangle.json'
import postStamp from '../../../../assets/presets/get-subscribe-form-post-stamp.json'
import stamp from '../../../../assets/presets/get-subscribe-form-stamp.json'
import { designBoxStyle } from './get-subscribers-style'
import { layoutStamp, layoutPostStamp, getElementLayout } from './get-subscribers-layout'
const fixSideLabelSkin: GetSubscribersEnhancer = (settings, convertedForm) => {
  if (!isSideLabelSkinGS(settings)) {
    return convertedForm
  }

  convertedForm.components = _.map(convertedForm.components, (comp) => ({
    ...comp,
    components: comp.components.reduce((currComponents, component) => {
      if (_.includes(FIELDS_ROLES, component.role)) {
        const inputComponent = _.merge({}, component, {
          data: { placeholder: '' },
          props: { placeholder: '' },
        })
        return [...currComponents, inputComponent]
      } else {
        return [...currComponents, component]
      }
    }, []),
  }))
  return convertedForm
}

const emailEnhancer: GetSubscribersEnhancer = (settings, structure) => {
  const supportNotifications = settings.generalSettings.subscriptionNotifications
  const emails = supportNotifications ? [settings.email].filter((email) => email) : []

  return {
    ...structure,
    config: {
      ...structure.config,
      emailIds: emails,
    },
  }
}
const designElementWithLayout = (
  element,
  extraData: GSExtraData,
  containerProp,
  style = {},
  getLayout: Function = getElementLayout,
) => {
  const desktopLayout = _.get(extraData.desktopLayouts, containerProp)
  const mobileLayouts = _.get(extraData.mobileLayouts, containerProp)
  return _.merge({}, element, {
    layout: getLayout(desktopLayout, element.layout),
    style,
    ...(mobileLayouts
      ? {
          mobileStructure: {
            layout: {
              ...element.layout,
              ...getLayout(mobileLayouts, element.layout),
            },
          },
        }
      : {}),
  })
}

const addDesignComponents: GetSubscribersEnhancer = (settings, convertedForm, extraData) => {
  const { appStyle } = settings.style

  const getDesignComponents = (stepIndex) => {
    const containerProp =
      appStyle === 0 ? 'headerContainer' : stepIndex === 0 ? 'appContainer' : 'successAppContainer'

    let designComponents = []
    const boxStyle = designBoxStyle(settings, extraData)
    let box = designElementWithLayout(greyBox, extraData, containerProp, { style: boxStyle })
    switch (appStyle) {
      case 0:
        if (stepIndex === 0) {
          designComponents.push(box)
        }

        break
      case 2:
        const postStampComp = designElementWithLayout(
          postStamp,
          extraData,
          containerProp,
          {},
          layoutPostStamp,
        )
        designComponents.push(box, postStampComp)
        break
      case 3:
        const stampComp = designElementWithLayout(stamp, extraData, containerProp, {}, layoutStamp)
        box.layout.height = box.layout.height - 5
        designComponents.push(box, stampComp)
        break
      case 4:
        const triangleComp = _.cloneDeep(triangle)
        designComponents.push(triangleComp, box)
        break
      default: {
      }
    }
    return designComponents
  }

  convertedForm.components = _.map(convertedForm.components, (comp, ind) => ({
    ...comp,
    components: [...getDesignComponents(ind), ...comp.components],
  }))

  return {
    ...convertedForm,
  }
}

export const hideFormInMobile: GetSubscribersEnhancer = (
  _setting,
  structure,
  extraData,
): RawComponentStructure => {
  if (!extraData.hiddenInMobile) {
    return structure
  }
  const hideFormInMobileAction: (structure: RawComponentStructure) => RawComponentStructure = (
    structure,
  ) => {
    const structureWithMobileHidden: RawComponentStructure = {
      ...structure,
      mobileHints: { hidden: true, type: 'MobileHints' },
    }
    if (structure.components) {
      return {
        ...structureWithMobileHidden,
        components: structureWithMobileHidden.components.map((component) =>
          hideFormInMobileAction(component),
        ),
      }
    } else {
      return structureWithMobileHidden
    }
  }
  return hideFormInMobileAction(structure)
}

export const mobileStructureStatesContainer: GetSubscribersEnhancer = (
  setting,
  structure,
  extraData,
  getSubscribersForm,
): RawComponentStructure => {
  if (extraData.hiddenInMobile) {
    return structure
  }

  const layout = getElementLayout(extraData.mobileLayouts.container)
  const layoutSuccess = getElementLayout(extraData.mobileLayouts.successContainer)
  let stepsLayouts = [layout, layoutSuccess]

  const containerMobileStructure = {
    layout: {
      x: getSubscribersForm.mobileStructure.layout.x,
      y: getSubscribersForm.mobileStructure.layout.y,
      width: extraData.mobileLayouts.container.width,
      height: extraData.mobileLayouts.container.height,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  }

  if (setting.style.appStyle === 3) {
    stepsLayouts = stepsLayouts.map((l) => ({ ...l, height: l.height - 16 }))
  }
  if (setting.style.appStyle === 4) {
    stepsLayouts = stepsLayouts.map((l) => ({ ...l, height: l.height - 12 }))
  }

  const mobileStructures = stepsLayouts.map((l) => ({
    layout: {
      ...l,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  }))

  return {
    ...structure,
    mobileStructure: containerMobileStructure,
    components: structure.components.map((comp, i) => ({
      ...comp,
      mobileStructure: mobileStructures[i],
    })),
  }
}

export const layoutStatesContainer: GetSubscribersEnhancer = (
  setting,
  structure,
  extraData,
): RawComponentStructure => {
  const layout = getElementLayout(extraData.desktopLayouts.container)
  const layoutSuccess = getElementLayout(extraData.desktopLayouts.successContainer)
  let layouts = [layout, layoutSuccess]

  if (setting.style.appStyle === 3) {
    layouts = layouts.map((l) => ({ ...l, height: l.height - 16 }))
  }
  if (setting.style.appStyle === 4) {
    layouts = layouts.map((l) => ({ ...l, height: l.height - 12 }))
  }

  return {
    ...structure,
    components: structure.components.map((comp, i) => ({
      ...comp,
      layout: layouts[i],
    })),
  }
}

export const formEnhancers: GetSubscribersEnhancer[] = [
  addDesignComponents,
  fixSideLabelSkin,
  emailEnhancer,
  hideFormInMobile,
  layoutStatesContainer,
  mobileStructureStatesContainer,
]
